<!-- ----------------------------------------------------------------------- -->
<!--
name    : APP TENANT CREATE

type    : view

uses    : header-view

route   : /tenants/create
 -->
<!-- ----------------------------------------------------------------------- -->
<template>
  <div class="app-tenant-create" v-if="tenant">
    <header-view
      title="Create a New Tenant"
      :previous_page="'/tenants'"
      :btnOptions="[
        {
          name: 'Save',
          action: this.handleSubmit,
        },
      ]" />
    <v-form ref="form">
      <v-container class="mx-2">
        <v-layout align-start justify-start row>
          <v-flex xs12>
            <v-text-field
              id="uuid"
              v-model="tenant.uuid"
              label="Tenant ID"
              disabled></v-text-field>
            <v-text-field
              id="tenant_name"
              v-model="tenant.tenant_name"
              label="Tenant Name"
              :rules="validate_tenant_name"></v-text-field>
            <v-text-field
              id="tenant_url"
              v-model="tenant.tenant_url"
              label="Tenant URL Extension"
              :rules="validate_tenant_url"></v-text-field>
          </v-flex>
          <v-flex xs12>
            <v-select
              id="account_status"
              :items="possible_account_status"
              v-model="tenant.account_status"
              label="Account Status"
              :rules="validate_account_status"></v-select>
            <v-select
              id="subscription_package"
              :items="subscription_packages"
              v-model="tenant.subscription_type"
              label="Subscription"
              :rules="validate_subscription_type"></v-select>
            <v-menu
              ref="menu"
              v-model="menu"
              :close-on-content-click="true"
              :nudge-right="40"
              :return-value.sync="tenant.subscription_start_date"
              transition="scale-transition"
              offset-y
              full-width
              min-width="290px"
              max-width="290px">
              <template v-slot:activator="{on}">
                <v-text-field
                  v-model="tenant.subscription_start_date"
                  label="Select Start Date"
                  v-on="on"
                  id="subscription_start_date"
                  readonly></v-text-field>
              </template>
              <v-date-picker
                v-model="tenant.subscription_start_date"
                no-title
                scrollable
                @click="$refs.menu.save(tenant.subscription_start_date)">
              </v-date-picker>
            </v-menu>
            <v-text-field
              id="subscription_end_date"
              v-model="tenant.subscription_end_date"
              label="Subscription Expiration Date"
              :rules="validate_subscription_end_date"
              disabled></v-text-field>
          </v-flex>
        </v-layout>
        <v-layout align-start justify-start row>
          <v-flex xs12>
            <v-text-field
              id="contact_phone"
              v-model="tenant.contact_phone"
              label="Contact Phone Number"
              :rules="validate_contact_phone"></v-text-field>
          </v-flex>
          <v-flex xs12>
            <v-text-field
              id="contact_email"
              v-model="tenant.contact_email"
              label="Contact Email Address"
              :rules="validate_contact_email"></v-text-field>
          </v-flex>
        </v-layout>
        <v-layout column justify-start row>
          <v-flex xs12>
            <v-text-field
              id="address"
              v-model="tenant.address"
              label="Address"
              :rules="validate_address"></v-text-field>
          </v-flex>
        </v-layout>
        <v-layout row>
          <v-flex xs12>
            <v-text-field
              id="city"
              v-model="tenant.city"
              label="City"
              :rules="validate_city"></v-text-field>
          </v-flex>
          <v-flex xs12>
            <v-combobox
              id="state"
              v-model="tenant.state"
              label="State"
              :rules="validate_state"
              :items="us_states"></v-combobox>
          </v-flex>
          <v-flex xs12>
            <v-text-field
              id="zip_code"
              v-model="tenant.zip_code"
              label="Postal Code"
              :rules="validate_zip_code"></v-text-field>
          </v-flex>
          <v-flex xs12>
            <v-select
              v-model="tenant.country"
              :items="countryItems"
              label="Country"
              hint=""
              :rules="validate_country"></v-select>
          </v-flex>
        </v-layout>
        <div class="headline" v-if="errorMessage !== ''">
          {{ errorMessage }}
          <v-btn @click="errorMessage = ''">OK</v-btn>
        </div>
      </v-container>
    </v-form>
  </div>
</template>

<script>
  // components
  import HeaderView from '@/components/header-view';

  // mixins
  import Forms from '@/mixins/forms.js';
  import Localization from '@/mixins/localization';

  // services
  import Tenants from '@/services/Tenants.service.js';

  export default {
    name: 'AppTenantCreate',
    components: {
      'header-view': HeaderView,
    },
    mixins: [Forms, Localization],
    props: {},
    data() {
      return {
        menu: false,
        possible_account_status: ['Active', 'Not Active'],
        subscription_packages: [
          {
            value: '3user',
            text: 'Base',
          },
          {
            value: '10user',
            text: 'Upgraded',
          },
          {
            value: 'UNLIMITED',
            text: 'Unlimited',
          },
          {
            value: 'FREE',
            text: 'Free',
          },
        ],
        errorMessage: '',
        loader: false,
        loading4: false,
        tenant: null,
        submitted: false,
        allowTenantCreate: false,
      };
    },
    watch: {
      loader() {
        const l = this.loader;
        this[l] = !this[l];

        setTimeout(() => (this[l] = false), 3000);

        this.loader = null;
      },
    },
    created() {
      this.getAllowed();
    },
    methods: {
      async getAllowed() {
        this.allowTenantCreate = this.$auth.isAllowed('tenants', 'create');

        // make sure we are allowed
        if (this.allowTenantCreate) {
          // Get the access token from the auth wrapper
          await this.$auth.getTokenSilently();

          // set up blank tenant
          this.tenant = Tenants.blankTenant();
        }
      },
      async handleSubmit() {
        if (!this.$refs.form.validate()) {
          console.log('failed validation');
          return false;
        }
        this.tenant.tenant_url = this.tenant.tenant_url.toLowerCase();
        // console.log( "Posting!" );
        // Get the access token from the auth wrapper
        const accessToken = await this.$auth.getTokenSilently();

        // not sure if these were needed but they were being set
        this.tenant.invoice_terms = 'Net 30';
        this.invoice_notes = 'Thank you for your business!';

        // create tenant
        var res = await Tenants.createTenant(this.tenant, accessToken);

        if (res) {
          this.$router.push({
            name: 'TenantView',
            params: {
              uuid: this.tenant.uuid,
            },
          });
        } else {
          this.errorMessage = 'An error occurred.';
          throw Error('Something went wrong');
        }
      },
    },
  };
</script>

<!-- Add 'scoped' attribute to limit CSS to this component only -->
<style scoped lang="scss">
  h3 {
    margin: 40px 0 0;
  }
  ul {
    list-style-type: none;
    padding: 0;
  }
  li {
    display: inline-block;
    margin: 0 10px;
  }
  a {
    color: #42b983;
  }

  .custom-loader {
    animation: loader 1s infinite;
    display: flex;
  }
  @-moz-keyframes loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
  @-webkit-keyframes loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
  @-o-keyframes loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
  @keyframes loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
</style>
